import Image from 'next/image';
import styles from './Thankyou.module.css';
import { SvgIcon } from '@components/icon';

const ThankYou = () => {
  const images = [
    '/images/thankyou-1.png',
    '/images/thankyou-2.png',
    '/images/thankyou-3.png',
    '/images/thankyou-4.png',
    '/images/thankyou-5.png',
    '/images/thankyou-6.png',
  ];

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.thankYouContainer}>
        <div className={styles.logoContainer}>
          <SvgIcon
            className="brand-logo"
            src="brightside-logo-dark.svg"
            alt="BrightSide Logo"
          />
        </div>
        <h1 className={styles.title}>Hey you! Thanks for stopping by.</h1>
        <p className={styles.text}>
          We've had so much fun this year but sadly we've had to close our
          doors.
        </p>
        <p className={styles.text}>
          Thank you for coming along this journey with us.
        </p>
        <p className={styles.textNew} style={{ marginBottom: 30 }}>
          May your light forever shine bright.
        </p>
        <div className={styles.imageGrid}>
          {images.map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              width={300}
              height={300}
              className={styles.gridImage}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
